.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
}

.title {
  width: -webkit-fill-available;
}

.item {
  position: relative;
  display: flex;
  flex: 0 0 100%;
  cursor: grab;
}

@media only screen and (min-width: 767px) {
  .item {
    flex: 0 1 auto;
    cursor: unset;
  }
}

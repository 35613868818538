.header {
  position: relative;
  background-size: cover;
}

.nakedHeader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 900;
  width: 100%;
}

.headerVideo {
  height: 100%;
  margin-bottom: 10px;
}

.video video {
  position: absolute;
  top: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.headerVideo::before {
  content: ' ';
  position: absolute;
  top: 0;
  z-index: 9;
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent 60%, black);
}

@media only screen and (max-width: 767px) {
  .video video {
    object-position: top;
  }
}

@media only screen and (min-width: 576px) {
  .headerVideo {
    height: 40vh;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .headerVideo {
    height: 320px;
  }
}

@media only screen and (min-width: 1200px) {
  .headerVideo {
    height: 320px;
  }
}

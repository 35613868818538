.trustBanner {
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 30px;
  background-color: var(--secondary-gray);
  touch-action: pan-y;
}

.wrapper {
  display: flex;
}

@media only screen and (min-width: 767px) {
  .wrapper {
    justify-content: space-between;
  }
}

.modalContent {
  margin-bottom: -16px;
  padding: 4px 4px 0 4px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.modalTextContainer {
  margin-bottom: 16px;
  padding: 24px;
  border-radius: 16px;
  background-color: rgba(242, 243, 246, 0.7);
  text-align: left;
  white-space: pre-wrap;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.twentyFourExtraText {
  opacity: 50%;
}

.payementLogos {
  margin-top: 16px;
}

.defaultLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 31px;
  border: 1px solid #eff0f4;
  border-radius: 5px;
  background: #fff;
}

.defaultStyle {
  height: 31px;
  margin-right: 8px;
}

.rounded {
  height: 29px;
  margin-right: 8px;
  border-radius: 3px;
}

.base {
  z-index: 40;
  box-sizing: content-box;
  height: 56px;
  color: white;
}

.baseRedBackground {
  display: flex;
  align-items: center;
  background-color: var(--primary-red);
}

.headerBorder {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.greyBackground.headerBorder {
  border-bottom: 1px solid #d4d4d5;
}

.innerWrapper {
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px 14px;
}

.topNav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.topNav > a,
.topNav > button {
  display: flex;
  align-items: center;
}

.topNavFunctions {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
}

.headerWithBanner {
  height: 86px;
}

@media only screen and (min-width: 768px) {
  .greyBackground.base {
    position: fixed;
    width: 100%;
    background: var(--colors-secondary-200);
  }

  .innerWrapper {
    padding: 16px 0;
  }

  .logo {
    height: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .wrapper {
    height: 100%;
    padding: 0;
  }

  .logoContainer {
    position: absolute;
    left: 40px;
  }

  .base {
    display: flex;
    flex-direction: column;
  }
}

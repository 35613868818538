.logosWrapper {
  display: flex;
  justify-content: center;
}

.left {
  justify-content: left;
}

.right {
  justify-content: right;
}

.logosWrapper > :last-child {
  margin-right: 0;
}
